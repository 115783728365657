import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "item-list"
}
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      "sub-heading": true,
      divider: false,
      title: _ctx.title
    }, null, 8, ["title"]),
    (!_ctx.editModeReactive && _ctx.selectedCheckboxOptions.length == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[2] || (_cache[2] = [
          _createElementVNode("span", null, "-", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mutableItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: _normalizeClass(["item", { 'item-margin': _ctx.editModeReactive || _ctx.showItem(item) }])
            }, [
              (_ctx.showItem(item))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[3] || (_cache[3] = _createElementVNode("i", {
                      class: "icon-circle_check_outline",
                      "aria-hidden": "true"
                    }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(item.text), 1)
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.editModeReactive)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_Checkbox, {
                      id: item.id,
                      modelValue: _ctx.selectedCheckboxOptions,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCheckboxOptions) = $event)),
                      name: item.id,
                      value: item,
                      class: "item-choice",
                      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onValueChanged()))
                    }, null, 8, ["id", "modelValue", "name", "value"]),
                    _createElementVNode("label", {
                      for: item.id,
                      class: "label-with-icon"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(item.text), 1)
                    ], 8, _hoisted_3)
                  ], 64))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ]))
  ], 64))
}