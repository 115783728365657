
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import { Diseases, Objectives, SurveyCgmInitial, SurveyCgmInitialFormDto, SurveyType } from '@/models/Survey';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import CardComponent from '../CardComponent.vue';
import MultiSelectBox from '../common/MultiSelectBox.vue';
import MultiCheckBox from '../common/MultiCheckBox.vue';
import EmptyState from '../EmptyState.vue';
import { PatientInfoItem } from '@/models/PatientInfo';
import SurveyHelper from '@/helpers/Surveys.helper';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  components: { EmptyState, CardComponent, MultiSelectBox, MultiCheckBox, HealzInput },

  setup() {
    const toast = useToast();
    const { t } = useI18n();
    const route = useRoute();
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const isAnthropometricDataNotReady = ref(true);
    const editMode = ref(false);
    const waitSubmit = ref(false);

    const surveyCgmInitialDefault = ref<SurveyCgmInitial>();
    const surveyCgmInitialFormDto = ref<SurveyCgmInitialFormDto>();

    const refDisease = ref();
    const refDiseaseDescription = ref();
    const refDiseaseFamily = ref();
    const refDrug = ref();

    const fetchCgmInitialSurvey = async () => {
      const result = await patientService.findSurveyCgmInitial(patientId);
      if (!(result instanceof ServiceError)) {
        surveyCgmInitialDefault.value = { ...result };
        surveyCgmInitialFormDto.value = new SurveyCgmInitialFormDto(surveyCgmInitialDefault.value);
        if (!surveyCgmInitialFormDto.value.id) {
          surveyCgmInitialFormDto.value.id = SurveyType.CGM_INITIAL;
        }
        isAnthropometricDataNotReady.value = false;
      }
    };

    onMounted(async () => {
      await fetchCgmInitialSurvey();
    });

    const onSubmit = async () => {
      if (surveyCgmInitialFormDto.value) {
        try {
          waitSubmit.value = true;
          await patientService.modifySurveyCgmInitial(patientId, surveyCgmInitialFormDto.value.toSurveyCgmInitial());
          toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
        } catch (error) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } finally {
          waitSubmit.value = false;
          editMode.value = false;
        }
      }
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      setInitialVaules();
    };

    const setInitialVaules = () => {
      if (surveyCgmInitialDefault.value) {
        surveyCgmInitialFormDto.value = new SurveyCgmInitialFormDto(surveyCgmInitialDefault.value);
        resetAllMultiSelect();
      }
    };

    const resetAllMultiSelect = () => {
      refDisease.value?.setInitialValues();
      refDiseaseDescription.value?.setInitialValues();
      refDiseaseFamily.value?.setInitialValues();
      refDrug.value?.setInitialValues();
    };

    const diseaseOptions = computed(() =>
      SurveyHelper.generateYesNoOptions('DISEASE', surveyCgmInitialDefault.value?.answers, t),
    );

    const diseaseFamilyOptions = computed(() =>
      SurveyHelper.generateYesNoOptions('DISEASE_FAMILY', surveyCgmInitialDefault.value?.answers, t),
    );

    const drugOptions = computed<PatientInfoItem[]>(() =>
      SurveyHelper.generateYesNoOptions('DRUG', surveyCgmInitialDefault.value?.answers, t),
    );

    const diseaseDescriptionOptions = computed(() => {
      if (surveyCgmInitialDefault.value !== undefined && surveyCgmInitialFormDto.value !== undefined) {
        return Object.keys(Diseases).map((k) => ({
          id: k,
          text: t(`personData.diseaseDescription.${k}`),
          value:
            surveyCgmInitialDefault.value?.answers &&
            surveyCgmInitialDefault.value.answers.DISEASE_DESCRIPTION &&
            surveyCgmInitialDefault.value?.answers.DISEASE_DESCRIPTION?.indexOf(k) != -1,
        }));
      } else {
        return [];
      }
    });

    return {
      isAnthropometricDataNotReady,
      surveyCgmInitialFormDto,
      editMode,
      waitSubmit,
      Objectives,
      diseaseOptions,
      diseaseDescriptionOptions,
      diseaseFamilyOptions,
      drugOptions,
      refDisease,
      refDiseaseDescription,
      refDiseaseFamily,
      refDrug,
      onCancelEdition,
      onSubmit,
    };
  },
});
