
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { PatientInfoItem } from '@/models/PatientInfo';
import HeadingComponent from '../HeadingComponent.vue';

export default defineComponent({
  components: { HeadingComponent },
  emits: ['update:modelValue'],
  props: {
    modelValue: null,
    editMode: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<PatientInfoItem[]>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const editModeReactive = computed(() => props.editMode);
    const mutableItems: Ref<PatientInfoItem[]> = ref([]);
    const selectedCheckboxOptions: Ref<PatientInfoItem[]> = ref([]);

    const isItemSelected = (item: PatientInfoItem) => {
      return selectedCheckboxOptions.value.includes(item);
    };

    const showItem = (item: PatientInfoItem) => {
      return editModeReactive.value === false && isItemSelected(item);
    };

    const setInitialValues = () => {
      mutableItems.value = [...props.options];
      selectedCheckboxOptions.value = mutableItems.value.filter((i) => i.value === true);
    };

    onMounted(() => setInitialValues());

    const onValueChanged = () => {
      let newValues: string | string[] = mutableItems.value.filter((i) => isItemSelected(i)).map((i) => i.id);
      emit('update:modelValue', newValues);
    };

    return {
      editModeReactive,
      mutableItems,
      selectedCheckboxOptions,
      showItem,
      onValueChanged,
      setInitialValues,
    };
  },
});
